.app__gallery {
    flex-direction: row;

    background: var(--color-black);
    padding: 4rem 0 4rem 6rem;
}
.sdd {
    font-size: 34px;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .side{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
  }
  h3{
    font-size: 3rem;
  }
  .slider-card-description {
    opacity: 1.0;
    font-size: 16px;
    font-weight: 200;
}
.secondaryTexts {
    color: rgb(46, 155, 155);
    font-size:2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .slider-card-title {
    font-weight: 500;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    color: orange;
}

.slider-card-description {
    opacity: 1.0;
    font-size: 16px;
    font-weight: 200;
    display: flex;
    justify-content: center;
    color: rgb(176, 194, 77);
}

.app__gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

#slider {
    width: 96.5%;
    height: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
   
    
}
.slider-icon.left {
    left: -51px;
}

.slider-card {
    width: 282px;
    height: 300px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    background: black;
}

@media screen and (min-width: 2000px) {
   }

    .app__gallery-content {
   display: none;
    }

   


@media screen and (max-width: 1150px) {
    .app__gallery {
    display: none;
    }

    .side{
        display: none;

    }
    .slider-icon.left {
     display: none;
    }

   
}

@media screen and (max-width: 850px) {
    .app__gallery {
        display: none;
    }
    .side{
       display: none;

    }
    .slider-icon.left {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
       display: none;
    }  
    
    .app__gallery-content {
      display: none;
    }
    .side{
        display: none;

    }
    .slider-icon.left {
        display: none;
    }

    .slider-icon.right {
        display: none;
    }
   
    .slider-card {
   display: none;
    }
}